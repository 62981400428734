import React, { useContext } from 'react';
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'

export default function New_Leave_Request_thankyou() {
  const { URL } = useContext(LeaveContext)

  const TheUrl = process.env.NODE_ENV === 'development'
    ? URL.replace('https://www.DecisionOneleave.com/ViewLeaveRequest.html', 'localhost:8000/ViewLeaveRequest/')
    : URL
      ? URL.replace('https://www.DecisionOneleave.com/ViewLeaveRequest.html', 'https://www.DecisionOneleave.com/ViewLeaveRequest/')
      : ''

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Leave Request Thank You" />
        <div className="content-outline-998">
          <div className="pad20" align="center">
            <p align="right">&nbsp;</p>
            <h1 align="center" className="color3">
              Thank you for submitting your Leave Request!
            </h1>
            A DecisionOne Leave Center representative will contact you within 24 hours with additional information.  Your manager will be notified of your request for a Leave of Absence.
            <h3>&nbsp;</h3>
            <h6 className="boldtext" align="center">
              {' '}
              To view a copy of your leave request please login at the
              following URL:
            </h6>
            <h6 className="color1" align="center">
              <a
                href={TheUrl}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                <strong>
                  {TheUrl}
                </strong>
              </a>
            </h6>
          </div>
        </div>
        <div className="whiteMask" />
      </Layout>
    </PrivateRoute>
  )
}
